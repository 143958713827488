var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('header', {
    staticClass: "d-flex justify-content-between align-items-center mb-2"
  }, [_c('h4', {
    staticClass: "text-secondary"
  }, [_c('strong', [_vm._v(_vm._s(_vm.bop.no))])])]), _vm._m(0)])], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-end mb-2"
  }, [_c('section', [_vm.isSPV ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.status.kode == 1 ? _c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmApprove2($event);
      }
    }
  }, [_vm._v("Setujui Ajuan")]) : _vm._e(), _vm.canCancel && _vm.status.kode != 4 ? _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancel($event);
      }
    }
  }, [_vm._v("Tolak Ajuan")]) : _vm._e(), _vm.status.kode == 4 ? _c('b-button', {
    attrs: {
      "variant": "secondary",
      "disabled": ""
    }
  }, [_vm._v("BOP telah diselesaikan")]) : _vm._e()], 1) : _vm._e(), _vm.isSales ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.status.kode == 3 ? _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmApprove2($event);
      }
    }
  }, [_vm._v("Terima Uang")]) : _c('b-button', {
    attrs: {
      "variant": "secondary",
      "disabled": ""
    }
  }, [_vm._v("Ajuan telah diselesaikan")])], 1) : _vm._e(), _vm.isFinance ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.status.kode == 2 ? _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.confirmApprove();
      }
    }
  }, [_vm._v("Pilih Sumber Pengeluaran")]) : _vm._e(), _vm.status.kode == 2 ? _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.confirmtolak();
      }
    }
  }, [_vm._v("Tolak Ajuan")]) : _vm._e(), _vm.status.kode == 4 ? _c('b-button', {
    attrs: {
      "variant": "secondary",
      "disabled": ""
    }
  }, [_vm._v("Ajuan telah diselesaikan")]) : _vm._e()], 1) : _vm._e()])]), _vm._t("default"), _c('b-modal', {
    attrs: {
      "id": "kas-modal",
      "title": "Pilih Akun & Kas",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isValidAkunForm
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.approvereal($event);
            }
          }
        }, [_vm._v(" Approve ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalapprfinance,
      callback: function callback($$v) {
        _vm.showModalapprfinance = $$v;
      },
      expression: "showModalapprfinance"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Nominal BOP Sales"
    }
  }, [_c('h4', [_c('i', {
    staticClass: "text-success"
  }, [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.bop.nominal)))])])])]), _c('b-form-group', {
    attrs: {
      "label": "Pilih Akun",
      "label-for": "vue-select-akun"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-1",
    attrs: {
      "id": "vue-select-akun",
      "options": _vm.id_akun
    },
    model: {
      value: _vm.form2.id_akun,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "id_akun", $$v);
      },
      expression: "form2.id_akun"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Pilih Kas",
      "label-for": "vue-select"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-1",
    attrs: {
      "id": "vue-select",
      "options": _vm.id_kas
    },
    model: {
      value: _vm.form2.id_kas,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "id_kas", $$v);
      },
      expression: "form2.id_kas"
    }
  }), _vm.invalidKas ? _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("Saldo Kas kurang dari nominal BOP Sales")]) : _vm._e()], 1)], 1)], 1)], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('table', {
    staticClass: "table table-responsive table-borderless"
  }, [_c('tr', [!_vm.isSales ? _c('th', [_vm._v("Nama Sales")]) : _vm._e(), _c('th', [_vm._v("Tanggal Ajuan")]), _c('th', [_vm._v("Nominal")]), _c('th', [_vm._v("Status Ajuan")]), _c('th', [_vm._v("Keterangan")])]), _c('tr', [!_vm.isSales ? _c('td', [_vm._v(_vm._s(_vm.bop.sales ? _vm.bop.sales.nama_lengkap : '-'))]) : _vm._e(), _c('td', [_vm._v(" " + _vm._s(_vm.bop.tanggal) + " ")]), _c('td', [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(_vm.bop.nominal)) + " ")]), _c('td', [_c('b-badge', {
    attrs: {
      "variant": _vm.status.variant
    }
  }, [_vm._v(" " + _vm._s(_vm.status.status) + " ")])], 1), _c('td', [_vm._v(_vm._s(_vm.bop.keterangan))])])])]);
}]

export { render, staticRenderFns }