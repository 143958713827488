<template>
    <b-card>
        <b-row>
            <b-col md="6" sm="4" class="my-1">
                <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click.prevent="add()"
                variant="primary"
                >
                <feather-icon icon="PlusIcon" class="mr-50" />
                Tambah
                </b-button>
            </b-col>
            <b-col v-if="rincian" md="6" sm="12" class="my-1 d-flex justify-content-end">
                <h3 class="text-danger">
                    <strong v-if="rincian.length > 0">
                    Total Harga: Rp {{ rincian.reduce((total, item) => total += parseInt(item.harga), 0) }}
                    </strong>
                    <strong v-else>Total Harga: Rp 0</strong>
                </h3>
            </b-col>
        </b-row>
        <b-row>
            <!-- Filter for tabkle -->
            <b-col md="2" sm="4" class="my-1">
                <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-50"
                />
                </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
                <b-form-group
                label="Sort"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
                >
                <b-input-group size="sm">
                    <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                    >
                    <template v-slot:first>
                        <option value="">-- none --</option>
                    </template>
                    </b-form-select>
                    <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                    </b-form-select>
                </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
                <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
                >
                <b-input-group size="sm">
                    <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                    />
                    <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                    </b-input-group-append>
                </b-input-group>
                </b-form-group>
            </b-col>
            <!-- / -->
            <!-- Table Data -->
            <b-col cols="12">
                <b-table
                    striped
                    hover
                    responsive
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="items"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                    <template #cell(no)="{index}">
                        {{index+1}}
                    </template>
                    <template #cell(status)>
                        <b-badge variant="success">Selesai</b-badge>
                    </template>
                    <template #cell(actions)>
                        <b-button
                            size="sm"
                            variant="outline-info"
                            @click.prevent="add"
                        >
                            <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button
                            size="sm"
                            variant="outline-danger"
                        >
                            <feather-icon icon="TrashIcon" />
                        </b-button>
                    </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
                />
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import {
    BCard,
  BTable,
  BTooltip,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox
} from "bootstrap-vue"
import Ripple from 'vue-ripple-directive'

export default {
    computed: {
        sortOptions() {
        // Create an options list from our fields
        return this.fields
            .filter((f) => f.sortable)
            .map((f) => ({ text: f.label, value: f.key }));
        },
        rincian() {
            return this.$store.state['nota-bop'].datas
        }
    },
    components: {
        BTable,
        Ripple,
        BTooltip,
        BCard,
        VBTooltip,
        BDropdown,
        BDropdownItem,
        BAvatar,
        BBadge,
        BModal,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BFormCheckbox
    },
    methods: {
        add() {
            this.$emit('showReimburse')
        }
    },
    data: () => ({
        perPage: 5,
        pageOptions: [3, 5, 10],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
            {key: 'no', label: 'No'},
            {key: 'tanggal', label: 'Tanggal'},
            {key: 'keterangan', label: 'Keterangan'},
            {key: 'status', label: 'Status'},
            {key: 'actions', label: '#'}
        ],
        items: [
            {
                id: 1,
                tanggal: '2022-09-09',
                keterangan: 'Karena udah ngeluarin banyak uang saya'
            }
        ]
    })
}
</script>