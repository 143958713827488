var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-tabs', {
    attrs: {
      "vertical": _vm.isVerticalTab,
      "nav-wrapper-class": "nav-vertical"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": _vm.titleTab,
      "title": "Info BOP"
    }
  }, [_c('bop-info', {
    attrs: {
      "bop": _vm.bop
    },
    on: {
      "loading": _vm.doLoading,
      "refreshData": _vm.refreshData
    }
  }, [_vm.isReimburse || _vm.isPengembalian && _vm.bop.appr_terima > 0 ? _c('b-card', [_c('div', [_c('table', {
    staticClass: "table table-borderless"
  }, [_c('thead', [_vm.isFinance ? _c('tr', [_c('td', [_vm._v("Bukti Penerimaan BOP")]), _c('td'), _c('th', [_c('a', {
    staticClass: "text-primary",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.printBop();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Cetak ")], 1)])]) : _vm._e(), _c('tr', [_c('td', [_vm._v("Total LPJ")]), _c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.total)))])]), _vm.isReimburse && _vm.sudahSetor ? _c('tr', [_c('td', [_vm._v("Total Reimburse")]), _c('th', {
    attrs: {
      "colspan": _vm.isFinance ? 1 : 2
    }
  }, [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.sisa)))]), _vm.isFinance && _vm.sudahSetor ? _c('th', [_c('a', {
    staticClass: "text-primary",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.printSisa('reimburse');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Cetak ")], 1), _vm.isFinance && _vm.bop.info.reimburse && _vm.bop.info.reimburse.id_akun == null ? _c('a', {
    staticClass: "text-info mr-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.confirmdireimburse
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "LayersIcon"
    }
  }), _vm._v(" Tentukan Akun ")], 1) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.isPengembalian ? _c('tr', [_c('td', [_vm._v("Total Pengembalian")]), _c('th', {
    attrs: {
      "colspan": _vm.isFinance ? 1 : 2
    }
  }, [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.sisa)))]), _vm.isFinance && _vm.sudahSetor ? _c('th', [_c('a', {
    staticClass: "text-primary mr-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.printSisa('pengembalian');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Cetak ")], 1), _vm.isFinance && _vm.bop.info.pengembalian && _vm.bop.info.pengembalian.id_akun == null ? _c('a', {
    staticClass: "text-info mr-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.confirmdikembali
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "LayersIcon"
    }
  }), _vm._v(" Tentukan Akun ")], 1) : _vm._e()]) : _vm._e()]) : _vm._e()])])]), _c('b-modal', {
    attrs: {
      "id": "modal-select2",
      "title": "Pilih Akun & Kas (Bukti BOP)",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": ""
    },
    model: {
      value: _vm.showModalapprfinancebukti,
      callback: function callback($$v) {
        _vm.showModalapprfinancebukti = $$v;
      },
      expression: "showModalapprfinancebukti"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Pilih Akun",
      "label-for": "vue-select-akun"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "vue-select-akun",
      "options": _vm.id_akun
    },
    model: {
      value: _vm.form.id_akun,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "id_akun", $$v);
      },
      expression: "form.id_akun"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Pilih Kas",
      "label-for": "vue-select"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "vue-select",
      "options": _vm.id_kas
    },
    model: {
      value: _vm.form.id_kas,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "id_kas", $$v);
      },
      expression: "form.id_kas"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.bukti($event);
      }
    }
  }, [_vm._v(" Simpan ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-select2",
      "title": "Pilih Akun & Kas (Pengembalian)",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": ""
    },
    model: {
      value: _vm.showModalapprfinance,
      callback: function callback($$v) {
        _vm.showModalapprfinance = $$v;
      },
      expression: "showModalapprfinance"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Pilih Akun",
      "label-for": "vue-select-akun"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "vue-select-akun",
      "options": _vm.id_akun
    },
    model: {
      value: _vm.form2.id_akun,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "id_akun", $$v);
      },
      expression: "form2.id_akun"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Pilih Kas",
      "label-for": "vue-select"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "vue-select",
      "options": _vm.id_kas
    },
    model: {
      value: _vm.form2.id_kas,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "id_kas", $$v);
      },
      expression: "form2.id_kas"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.dikembali($event);
      }
    }
  }, [_vm._v(" Simpan ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-select3",
      "title": "Pilih Akun & Kas (Reimburse)",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": ""
    },
    model: {
      value: _vm.showModalapprfinance2,
      callback: function callback($$v) {
        _vm.showModalapprfinance2 = $$v;
      },
      expression: "showModalapprfinance2"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Pilih Akun",
      "label-for": "vue-select-akun"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "vue-select-akun",
      "options": _vm.id_akun
    },
    model: {
      value: _vm.form3.id_akun,
      callback: function callback($$v) {
        _vm.$set(_vm.form3, "id_akun", $$v);
      },
      expression: "form3.id_akun"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Pilih Kas",
      "label-for": "vue-select"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "vue-select",
      "options": _vm.id_kas
    },
    model: {
      value: _vm.form3.id_kas,
      callback: function callback($$v) {
        _vm.$set(_vm.form3, "id_kas", $$v);
      },
      expression: "form3.id_kas"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.direimburse($event);
      }
    }
  }, [_vm._v(" Simpan ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-tab', {
    attrs: {
      "active": _vm.LPJTab,
      "title": "LPJ BOP"
    }
  }, [_vm.bopHasDone ? _c('div', [_c('bop-rincian', {
    attrs: {
      "bop": _vm.bop,
      "rincian": _vm.rincian
    },
    on: {
      "showReimburse": function showReimburse($event) {
        _vm.showReimburse = true;
      },
      "showPengembalian": function showPengembalian($event) {
        _vm.showPengembalian = true;
      },
      "regetLaporan": _vm.getLaporan,
      "printLPJ": _vm.printLPJ,
      "changeActiveTab": _vm.changeActiveTab
    }
  })], 1) : _c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Menunggu Persetujuan SPV, Finance, dan Tanda terima dari Sales")])])])], 1)], 1), _c('modal-bop', {
    attrs: {
      "active": _vm.showReimburse,
      "label-type": "reimburse",
      "label": "Reimburse"
    },
    on: {
      "submit": _vm.saveReimburse
    }
  }), _c('modal-bop', {
    attrs: {
      "active": _vm.showPengembalian,
      "label-type": "pengembalian",
      "label": "Pengembalian BOP"
    },
    on: {
      "submit": _vm.savePengembalian
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }